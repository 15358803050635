import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Copyright from '../footer/Copyright';

const theme = createTheme();

export interface FolioViewProps {
  pageID: string,
  handlePageEvent: Function,
}

const getPage = (id: string) => {
  switch (id) {
    //#todo
    default:
      return (<>Folio Test</>);
      break;
  }
};

const FolioView: FC<FolioViewProps> = ({ pageID, handlePageEvent }): ReactElement => {
  console.log('FolioView rendering ' + pageID);

  const handlePageClick = (params: any) => {
    console.log('FolioView.handlePageClick() called with', params);
    handlePageEvent(params);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ minHeight: '80vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{
            backgroundImage: 'url(https://route.idlegarden.com/public/images/vine_pattern.jpg)',
            backgroundRepeat: 'repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'auto',
            backgroundPosition: 'center',
          }}
        >
          <Box sx={{
            width: '90%',
            maxWidth: '1280px',
            mt: '10px',
            mb: '20px',
            ml: 'auto',
            mr: 'auto',
            padding: '20px',
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '6px',
          }}>
            Folio Page
            {getPage(pageID)}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default FolioView;