import React, { FC, ReactElement } from 'react';
import { useState, useEffect } from 'react';
//import logo from './logo.svg';
import './App.css';

import HeaderBar from './components/appbar/HeaderBar';
import HomeView from './components/pageview/HomeView';
import MainView from './components/pageview/MainView';
import FolioView from './components/pageview/FolioView';
import Footer from './components/footer/FooterBar';

import { MenuItemProps, LinkImageProps } from './components/appbar/interfaces';
//import { getValue } from '@testing-library/user-event/dist/utils';

import testData from './mockdata/productList.json';

declare const window: any;
const stateObj = window.stateObj || { test: "test" };
stateObj.count = 0;

const randomHash = '23423523409578934875t4t345345'; //used to flush build hash
console.log('randomHash=' + randomHash);

const updateGlobal = (value: number) => {
  stateObj.count = value;
};

const menuItems: Map<string, MenuItemProps[]> = new Map<string, MenuItemProps[]>();

const topMenuList: MenuItemProps[] = [
  { id: '0', title: 'Home', link: '', subMenu: '', view: 'home', viewID: 'home', },
  { id: '1', title: 'Web Apps', link: '', subMenu: 'web-apps', view: 'main-page', viewID: 'web-apps', },  //indicates sub menu rollover
  { id: '2', title: 'Websites', link: '', subMenu: 'websites', view: 'main-page', viewID: 'websites', },  //indicates sub menu rollover
  { id: '3', title: 'Animations', link: '', subMenu: 'animations', view: 'main-page', viewID: 'animations', },  //indicates sub menu rollover
  { id: '4', title: 'Graphic Design', link: '', subMenu: 'graphic-design', view: 'main-page', viewID: 'graphic-design', },  //indicates sub menu rollover
  { id: '5', title: 'About', link: '', subMenu: '', view: 'main-page', viewID: 'about', },
];
menuItems.set('top', topMenuList);

menuItems.set('web-apps', [
  { id: '101', title: 'Apps in Dev', link: '', subMenu: '', view: 'folio-page', viewID: 'apps-in-dev', },
  { id: '102', title: 'Node.js', link: '', subMenu: '', view: 'folio-page', viewID: 'nodejs', },
  { id: '103', title: 'Other Apps', link: '', subMenu: '', view: 'folio-page', viewID: 'other-apps', },
  { id: '104', title: 'JavaScript Snippets', link: '', subMenu: '', view: 'folio-page', viewID: 'javascript-snippets', },
  { id: '105', title: 'WordPress', link: '', subMenu: '', view: 'folio-page', viewID: 'wordpress', },
  { id: '106', title: 'Actionscript', link: '', subMenu: '', view: 'folio-page', viewID: 'actionscript', },
  { id: '107', title: 'Java', link: '', subMenu: '', view: 'folio-page', viewID: 'java', },
]);
menuItems.set('websites', [
  { id: '201', title: 'Australian Institute of Architects', link: '', subMenu: '', view: 'folio-page', viewID: 'aia', },
  { id: '202', title: 'Monash University', link: '', subMenu: '', view: 'folio-page', viewID: 'monash', },
  { id: '203', title: 'Box Hill Institute', link: '', subMenu: '', view: 'folio-page', viewID: 'bhi', },
  { id: '204', title: 'YACMU', link: '', subMenu: '', view: 'folio-page', viewID: 'yacmu', },
  { id: '205', title: 'Misc Web Design', link: '', subMenu: '', view: 'folio-page', viewID: 'misc-web-design', },
]);
menuItems.set('animations', [
  { id: '301', title: 'St Columb\'s Church', link: '', subMenu: '', view: 'folio-page', viewID: 'st-columbs-church', },
  { id: '302', title: 'Minecraft Worlds', link: '', subMenu: '', view: 'folio-page', viewID: 'minecraft-worlds', },
  { id: '303', title: 'Skydeck Alpha', link: '', subMenu: '', view: 'folio-page', viewID: 'skydeck-alpha', },
  { id: '304', title: 'Hyperion Gallery', link: '', subMenu: '', view: 'folio-page', viewID: 'hyperion-gallery', },
  { id: '305', title: 'Hyperion Marooned', link: '', subMenu: '', view: 'folio-page', viewID: 'hyperion-marooned', },
  { id: '306', title: 'The Citadel', link: '', subMenu: '', view: 'folio-page', viewID: 'the-citadel', },
  { id: '307', title: 'Plaza', link: '', subMenu: '', view: 'folio-page', viewID: 'plaza', },
  { id: '308', title: 'Animated Clips', link: '', subMenu: '', view: 'folio-page', viewID: 'animated-clips', },
]);
menuItems.set('graphic-design', [
  { id: '401', title: 'Flyers & Brochures', link: '', subMenu: '', view: 'folio-page', viewID: 'flyers-brochures', },
  { id: '402', title: 'Banners & Logos', link: '', subMenu: '', view: 'folio-page', viewID: 'banners-logos', },
  { id: '403', title: 'Illustrations', link: '', subMenu: '', view: 'folio-page', viewID: 'illustrations', },
]);

const getMenuItemByID = (itemID: string) => {
  console.log('App.getMenuItemByID() called with itemID=' + itemID);
  //Typescript Map iterator
  for (const [key, value] of menuItems.entries()) {
    for (let i = 0, len = value.length; i < len; i++) {
      if (value[i].id === itemID) {
        return value[i];
      }
    }
  }
  return null;
};

const getMenuItemByTitle = (menuID: string, title: string) => {
  console.log('App.getMenuItemByTitle() called with menuID=' + menuID + ' title=' + title);
  let list = menuItems.get(menuID);
  if (list) {
    for (let i = 0, len = list.length; i < len; i++) {
      if (list[i].title === title) {
        return list[i];
      }
    }
  }
  return null;
};

interface HeaderUpdateProps {
  type: string
  value: string,
};

const App: FC = (): ReactElement => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [currentPageType, setCurrentPageType] = useState<string>('');
  const [currentPageID, setCurrentPageID] = useState<string>('');

  useEffect(() => {
    //initial external data fetching here
    //test using mock json file inside public folder
    console.log('App.useEffect() fetching mock data...');
    fetch('./mockdata/productList.json')
      .then((response) => {
        console.log('App.useEffect() fetch response=' + response.ok);
        if (!response.ok) {
          throw new Error(
            `This is an HTTP error: The status is ${response.status}`
          );
        }
        return response.json();
      })
      .then((jsonData) => {
        console.log('App.useEffect() fetch json', jsonData);
        if (jsonData && jsonData.productDetails) {
          console.log('App.useEffect() fetch json is valid');
          //setProductData(jsonData.productDetails);
          setError(null);
        } else {
          console.error('!App.useEffect() fetch json is invalid');
          setError('invalid product details json file');
          //setProductData(null);
        }
      })
      .catch((err) => {
        console.error('!App.useEffect() fetch is invalid', err);
        setError(err.message);

        if (testData && testData.productDetails) {
          console.log('App.useEffect() using local mock data...');
          //setProductData(testData.productDetails);
        } else {
          //setProductData(null);
        }
      })
      .finally(() => {
        console.log('App.useEffect() fetch complete.');
        setLoading(false);
      });
  }, []);

  const handlePageEvent = (params: any) => {
    console.log('App.handlePageEvent() called with', params);
    switch (params.type) {
      case 'view-page':
        switch (params.value) {
          case 'web-applications':
            changeView('main-page', 'web-apps');
            break;
          case 'web-aia':
          case 'web-monash':
          case 'web-bhi':
          case 'web-aia':
          case 'web-yacmu':
          case 'web-misc':
          case 'website-development':
            changeView('main-page', 'websites');
            break;
          case 'graphic-design':
            changeView('main-page', 'graphic-design');
            break;
          case 'animation':
            changeView('main-page', 'animations');
            break;
          default:
            //must be a sub section
            break;
        }
        break;
      default:
        break;
    }
  };

  const changeView = (viewType: string, viewID: string) => {
    console.log('App.changeView() called with viewType=' + viewType + ' viewID=' + viewID);
    switch (viewType) {
      case 'main-page':
        window.scrollTo(0, 0);
        setCurrentPageType('main-page');
        setCurrentPageID(viewID);
        break;
      case 'folio-page':
        window.scrollTo(0, 0);
        setCurrentPageType('folio-page');
        setCurrentPageID(viewID);
        break;
      case 'home':
      default:
        window.scrollTo(0, 0);
        setCurrentPageType('home');
        setCurrentPageID(viewID);
        break;
    }
  };

  const handleHeaderUpdate = (params: HeaderUpdateProps) => {
    console.log('App.handleHeaderUpdate() called with', params);
    switch (params.type) {
      case 'menu_item_click':
        let menuItem: MenuItemProps | null = getMenuItemByID(params.value);
        if (menuItem) {
          changeView(menuItem.view, menuItem.viewID);
        }
        break;
      case 'link_click':
        if (params.value && params.value !== '') {
          window.location.href = params.value;
        }
        break;
      default:
        break;
    }
  };

  const handleFooterUpdate = (params: any) => {
    console.log('App.handleFooterUpdate() called with', params);
    //#todo
  };

  const getCurrentPage = () => {
    console.log('App.getCurrentPage() rendering ' + currentPageType + ' currentPageID=' + currentPageID);
    switch (currentPageType) {
      case 'main-page':
        console.log('App.getCurrentPage() main-page');
        return (<MainView pageID={currentPageID} handlePageEvent={handlePageEvent} />)
        break;
      case 'folio-page':
        console.log('App.getCurrentPage() folio-page');
        return (<FolioView pageID={currentPageID} handlePageEvent={handlePageEvent} />)
        break;
      case 'home':
      default:
        console.log('App.getCurrentPage() home');
        return (<HomeView pageID={currentPageID} handlePageEvent={handlePageEvent} />)
        break;
    }
  };

  return (
    <div className="App" id="top">
      <React.Fragment>
        <HeaderBar
          topMenuItems={topMenuList}
          menuItemMap={menuItems}
          handleHeaderUpdate={handleHeaderUpdate}
        />
        {getCurrentPage()}
        <Footer handleFooterUpdate={handleFooterUpdate} />
      </React.Fragment>
    </div>
  );
};

export default App;
