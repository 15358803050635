import React, { FC, ReactElement } from 'react';
import { useState } from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NearMeIcon from '@mui/icons-material/NearMe';
import LanguageIcon from '@mui/icons-material/Language';

import AdbIcon from '@mui/icons-material/Adb';

import { MenuItemProps, MenuListProps, LinkImageProps, LinkTextProps } from './interfaces';

export interface MenuBarCallProps {
  topMenuItems: MenuItemProps[],
  subMenuItems: Map<string, MenuItemProps[]>,
  handleMenuUpdate: Function,
};

const MenuBar: FC<MenuBarCallProps> = ({ topMenuItems, subMenuItems, handleMenuUpdate }): ReactElement => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: any) => {
    console.log('MenuBar.handleOpenNavMenu() called with ' + event.target.value);
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    console.log('MenuBar.handleCloseNavMenu() called.');
    setAnchorElNav(null);
  };

  const handleLogoClick = () => {
    console.log('MenuBar.handleLogoClick() called.');
    //#todo
  };

  const handleMenuItemClick = (itemID: string) => {
    console.log('MenuBar.handleMenuItemClick() called with ' + itemID);
    handleCloseNavMenu();
    handleMenuUpdate({
      type: 'menu_item_click',
      value: itemID,
    });
  };

  //Logo (small screen only)
  const LogoSmall = () => {
    return (
      <Typography
        variant="h5"
        noWrap
        component="a"
        href=""
        sx={{
          display: { xs: 'block', md: 'none' },
          flexGrow: 1,
          fontFamily: 'noto sans,sans-serif',
          fontWeight: 700,
          letterSpacing: '.3rem',
          color: '#ccc',
          textDecoration: 'none',
          backgroundColor: 'none',
          mr: 'auto',
          ml: 'auto',
          mt: '34px',
          mb: '8px',
        }} >
        Tony Gillan
      </Typography>
    )
  };

  //Main Menu Bar (small screen only)
  const MainMenuBarSmall = () => {
    return (
      <Box sx={{
        flexGrow: 2,
        display: { xs: 'flex', md: 'none' },
        backgroundColor: 'none',
        color: '#000',
        width: '50px',
        position: 'absolute',
      }}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar-small"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          sx={{
            mt: '30px',
            backgroundColor: 'rgba(255,255,255,0.4)',
          }}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar-small"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          sx={{
            display: { xs: 'block', md: 'none' },
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        >
          { topMenuItems.map((menuItem) => (
            <MenuItem
              key={menuItem.title}
              onClick={() => handleMenuItemClick(menuItem.id)}
            >
              <Typography textAlign="center">{menuItem.title}</Typography>
            </MenuItem>
          )) }
        </Menu>
      </Box>
    )
  };

  //Main Menu Bar (large screen only)
  const MainMenuBarLarge = () => {
    return (
      <Box sx={{
        flexGrow: 1,
        display: { xs: 'none', md: 'block',  maxWidth: '700px', ml: 'auto', mr: 'auto' }
      }}>
        { topMenuItems.map((menuItem) => (
          <Button
            key={menuItem.title}
            onClick={() => handleMenuItemClick(menuItem.id)}
            sx={{ my: 2, color: '#fff', display: 'inline-block', mr: '4px' }}
            variant="outlined"
          >
            {menuItem.title}
          </Button>
        )) }
      </Box>
    )
  };

  return (
    <React.Fragment>
      {/* Main Menu Bar (small screen only) */}
      <MainMenuBarSmall />
      {/* Logo (small screen only) */}
      <LogoSmall />
      {/* Main Menu Bar (large screen only) */}
      <MainMenuBarLarge />
    </React.Fragment>
  );
}

export default MenuBar;
