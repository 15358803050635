import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';

import HTMLText from './HTMLText';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { url } from 'inspector';

import BasicModal from './BasicModal';

export interface ThumbBlockProps {
  id: number,
  title: string,
  link: string, //if external link blank, then link to full size image instead
  src: string,
  srcWidth: number,
  srcHeight: number,
  boxWidth: number,
  boxHeight: number,
  handleImageBlockClick: Function,
}

const ThumbBlock: FC<ThumbBlockProps> = ({ id, title, link, src, srcWidth, srcHeight, boxWidth, boxHeight, handleImageBlockClick }): ReactElement => {

  return (
    <Box sx={{ border: '1px solid rgba(255,255,255,0.5)', height: boxHeight + "px", width: boxWidth + "px", margin: '20px', display: 'inline-block' }}>
      <Link>{srcWidth > srcHeight ? (
        <img src={src} height="100%" title={title} />
      ) : (
        <img src={src} width="100%" title={title} />
      )}
      </Link><br />
      {title}
      <BasicModal id={0} title={title} link={link} src={src} srcWidth={srcWidth} srcHeight={srcHeight} boxWidth={boxWidth*2} boxHeight={boxHeight*2} />
    </Box>
  )
};

export default ThumbBlock;