import React, { FC, ReactElement } from 'react';
import { useState } from 'react';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

type CopyrightProps = {
    sx?: any,
};

const Copyright: FC<CopyrightProps> = ({ sx }): ReactElement => { 
  return (
    <Typography variant="body2" color="text.secondary" align="center" sx={sx}>
      {'Copyright © '}
      <Link color="inherit" href="https://skylightcreative.com.au">
        Skylight Creative
      </Link>{' '}
      {new Date().getFullYear()}
      {'. All rights are reserved.'}
    </Typography>
  );
};

export default Copyright;