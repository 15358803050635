import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import ThumbBlock from './ThumbBlock';


const theme = createTheme();

export interface GraphicDesignProps {
  anchor: string,
  handlePageClick: Function,
}

const GraphicDesign: FC<GraphicDesignProps> = ({ anchor, handlePageClick }): ReactElement => {

  const handleImageBlockClick = (id: number) => {
    console.log('GraphicDesign.handleImageBlockClick() called with id=' + id);
  }

  return (
    <>

      <Box sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h4" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Graphic Design
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          An archive of graphic design & print projects.
        </Typography>
      </Box>
      <Box id='flyers-brochures' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Flyers &amp; Brochures
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Various flyers and brochures designed and produced for print
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <Box>
            <ThumbBlock id={0} title="Sibelius Composer Awards 2008" link="" src="./portfolio/design/1_large.jpg"
              srcWidth={1545} srcHeight={2182} boxWidth={152} boxHeight={214} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={1} title="Digistry Enhance Flyer 2" link="" src="./portfolio/yacmu/enhance_flyer2.jpg"
              srcWidth={348} srcHeight={450} boxWidth={152} boxHeight={196} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={2} title="Digistry Enhance Audio CD Slip" link="" src="./portfolio/yacmu/enhance_audio_slip.jpg"
              srcWidth={480} srcHeight={252} boxWidth={288} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={3} title="Digistry Enhance DVD Slip" link="" src="./portfolio/yacmu/enhance_dvd_slip.jpg"
              srcWidth={480} srcHeight={323} boxWidth={225} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={4} title="Digistry Enhance Flyer 1" link="" src="./portfolio/yacmu/enhance_flyer1.jpg"
              srcWidth={480} srcHeight={220} boxWidth={329} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={5} title="Digistry Enhance Flyer 3" link="" src="./portfolio/design/7a_large.jpg"
              srcWidth={2073} srcHeight={954} boxWidth={328} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={6} title="Digistry Enhance Display Banner" link="" src="./portfolio/yacmu/digistry_banner.jpg"
              srcWidth={480} srcHeight={480} boxWidth={152} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={7} title="Alloy Computing Hardware Flyer" link="" src="./portfolio/design/5_large.jpg"
              srcWidth={1595} srcHeight={2033} boxWidth={152} boxHeight={193} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={8} title="Alloy Computing Hardware Ad" link="" src="./portfolio/design/6_large.jpg"
              srcWidth={1425} srcHeight={940} boxWidth={229} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={9} title="MTEC09 Conference Flyer" link="" src="./portfolio/design/4_large.jpg"
              srcWidth={2076} srcHeight={1468} boxWidth={214} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={10} title="ISCAST Intelligent Design & Science Flyer" link="" src="./portfolio/design/3_large.jpg"
              srcWidth={1545} srcHeight={2186} boxWidth={152} boxHeight={214} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={11} title="ISCAST Random Design Flyer" link="" src="./portfolio/design/2_large.jpg"
              srcWidth={1541} srcHeight={2182} boxWidth={152} boxHeight={214} handleImageBlockClick={handleImageBlockClick} />
          </Box>
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" href="#top" title="Back to the top">
            <img src="./portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='banners-logos' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Banners &amp; Logos
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Various website banners and logos
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <Box>
            <ThumbBlock id={0} title="Skylight Multimedia Banner" link="" src="./portfolio/design/skylight_logo_small_080601.png"
              srcWidth={200} srcHeight={105} boxWidth={202} boxHeight={107} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={1} title="MTEC09 Banner" link="" src="./portfolio/design/4a_large.jpg"
              srcWidth={1548} srcHeight={446} boxWidth={675} boxHeight={196} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={2} title="360 Degrees Logo" link="" src="./portfolio/design/360_logo.jpg"
              srcWidth={461} srcHeight={450} boxWidth={156} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={3} title="VOSA Banner" link="" src="./portfolio/design/6a_large.jpg"
              srcWidth={1425} srcHeight={432} boxWidth={497} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
          </Box>
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" href="#top" title="Back to the top">
            <img src="./portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
      <Box id='illustrations' sx={{
        width: '90%',
        maxWidth: '1280px',
        mt: '10px',
        mb: '20px',
        ml: 'auto',
        mr: 'auto',
        padding: '20px',
        pt: '60px',
        pb: '60px',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderRadius: '6px',
      }}>
        <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Illustrations
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          Various posters, mockups and illustrations
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
          <Box>
            <ThumbBlock id={0} title="YAMCU - Smashing Jam Poster Illustration" link="" src="./portfolio/illustrations/ill18.jpg"
              srcWidth={370} srcHeight={480} boxWidth={152} boxHeight={197} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={1} title="AIPMM - Trade Magazine Cover Illustration" link="" src="./portfolio/illustrations/ill17.jpg"
              srcWidth={339} srcHeight={480} boxWidth={152} boxHeight={214} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={2} title="AIPMM - Trade Magazine Cover Illustration" link="" src="./portfolio/illustrations/ill15.jpg"
              srcWidth={334} srcHeight={480} boxWidth={152} boxHeight={218} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={3} title="JT-Press - Christmas Card Illustration" link="" src="./portfolio/illustrations/ill13.jpg"
              srcWidth={251} srcHeight={480} boxWidth={152} boxHeight={289} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={4} title="Enfin Design - Bird Logo Illustration" link="" src="./portfolio/illustrations/ill07.jpg"
              srcWidth={466} srcHeight={480} boxWidth={152} boxHeight={157} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={5} title="SLR Camera Illustration" link="" src="./portfolio/illustrations/ill06.jpg"
              srcWidth={480} srcHeight={389} boxWidth={187} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={6} title="Clarinet Illustration" link="" src="./portfolio/illustrations/ill05.jpg"
              srcWidth={480} srcHeight={278} boxWidth={261} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={7} title="Joico Biojoba Ad Mockup" link="" src="./portfolio/illustrations/ill04.jpg"
              srcWidth={307} srcHeight={480} boxWidth={152} boxHeight={237} handleImageBlockClick={handleImageBlockClick} />
            <ThumbBlock id={8} title="Studio Illustration" link="" src="./portfolio/illustrations/folio_art_01.jpg"
              srcWidth={165} srcHeight={137} boxWidth={183} boxHeight={152} handleImageBlockClick={handleImageBlockClick} />
          </Box>
        </Typography>
        <Box sx={{ mt: '40px', mb: '20px' }} >
          <Link color="inherit" href="#top" title="Back to the top">
            <img src="./portfolio/up_arrow_gold.png" loading="lazy" />
          </Link>
        </Box>
      </Box>
    </>
  )
};

export default GraphicDesign;