import React, { FC, ReactElement, ReactNode } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export interface BasicModalProps {
    id: number,
    title: string,
    link: string, //if external link blank, then link to full size image instead
    src: string,
    srcWidth: number,
    srcHeight: number,
    boxWidth: number,
    boxHeight: number,
}

const BasicModal: FC<BasicModalProps> = ({ id, title, link, src, srcWidth, srcHeight, boxWidth, boxHeight }): ReactElement => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen}>View</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {srcWidth > srcHeight ? (
                            <img src={src} width={boxWidth} title={title} />
                        ) : (
                            <img src={src} height={boxHeight} title={title} />
                        )}
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

export default BasicModal;