import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';

import HTMLText from './HTMLText';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { url } from 'inspector';

export interface LinkItem {
  type: string,
  value?: string,
  title?: string,
  url?: string,
  target?: string,
}

export interface BlockItem {
  title: string,
  description: string,
  imageSource: string,
  url: string,
  target: string,
}

export interface ListBlockProps {
  anchor: string,
  title: string,
  description: LinkItem[],
  itemList: BlockItem[],
  handleListBlockClick: Function,
}

const ListBlock: FC<ListBlockProps> = ({ anchor, title, description, itemList, handleListBlockClick }): ReactElement => {

  return (
    <Box id={anchor} sx={{
      width: '90%',
      maxWidth: '1280px',
      mt: '10px',
      mb: '20px',
      ml: 'auto',
      mr: 'auto',
      padding: '20px',
      pt: '60px',
      pb: '60px',
      backgroundColor: 'rgba(255,255,255,0.1)',
      borderRadius: '6px',
    }}>
      <Typography variant="h5" gutterBottom sx={{ color: '#fff', textAlign: 'center' }}>
        {title}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'left', ml: 'auto', mr: 'auto', maxWidth: '640px' }}>
        <HTMLText items={description} />
      </Typography>
      <Box sx={{ maxWidth: '640px', ml: 'auto', mr: 'auto', bgcolor: 'rgba(0,0,0,0.3)', padding: '20px', borderRadius: '5px' }}>
        <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'left' }}>
          {itemList.map((item) => (
            <Box sx={{ width: '100%', borderBottom: '1px solid #555', paddingBottom: '10px', marginBottom: '20px' }}>
              <Box sx={{ width: '20%', minWidth: '150px', display: 'inline-block', mr: '20px' }}>
                <Link href={item.url} title={item.title} target={item.target} underline="hover">
                  <img width="150" src={item.imageSource} alt={item.title} decoding="async" loading="lazy" />
                </Link>
              </Box>
              <Box sx={{ width: '70%', display: 'inline-block' }}>
                <Link href={item.url} title={item.title} target={item.target} underline="hover">{item.title}</Link><br />
                {item.description}
              </Box>
            </Box>
          )
          )}
        </Typography >
      </Box >
      <Box sx={{ mt: '40px', mb: '20px' }} >
        <Link color="inherit" href="#top" title="Back to the top">
          <img src="./portfolio/up_arrow_gold.png" loading="lazy" />
        </Link>
      </Box>
    </Box >
  )
};

export default ListBlock;