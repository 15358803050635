import React, { FC, ReactElement, ReactNode } from 'react';
import { useState } from 'react';

import Link from '@mui/material/Link';

import Typography from '@mui/material/Typography';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { url } from 'inspector';

export interface LinkItem {
  type: string,
  value?: string,
  title?: string,
  url?: string,
}

export interface HTMLTextProps {
  items: LinkItem[],
}

const getLinkIndexList = (test: string) => {
  let output: number[] = [];
  let index: number = 0;
  let len: number = test.length;
  const linkChar: string = '%';
  while (index < len - 1) {
    if (test.charAt(index) === linkChar) {
      if (test.charAt(index + 1) === linkChar) {
        //found insert point
        output.push(index);
        index++;  //allow for double %
      }
    }
    index++;
  }
  return output;
};

const HTMLText: FC<HTMLTextProps> = ({ items }): ReactElement => {

  return (
    <>
      <Typography variant="body1" gutterBottom sx={{ color: '#fff', textAlign: 'left', ml: 'auto', mr: 'auto', maxWidth: '640px' }}>
        {items.map((link) => {
          switch (link.type) {
            case 'link':
              return <Link href={link.url} title={link.title} underline="hover">{link.value}</Link>
              break;
            case 'break':
              return <br />
              break;
            case 'text':
              return link.value;
              break;
            default:
              return '';
              break;
          }
        })}
      </Typography>
    </>
  )
};

export default HTMLText;